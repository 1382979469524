import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import MenuIcon from '@material-ui/icons/Menu';
import { Link } from 'gatsby-plugin-react-i18next';
import styled from 'styled-components';
import { media } from '../style';

const useStyles = makeStyles({
    list: {
        width: 250,
    },
    fullList: {
        width: 'auto',
    },
});

type Anchor = 'top' | 'left' | 'bottom' | 'right';
const MobileMenuContainer = styled.div`
    ${media({ lg: 'display: none;', md: 'display: none;' })}
`;
const LargeMenuContainer = styled.div`
    display: none;
    ${media({ lg: 'display: block;', md: 'display: block;' })}
`;
export default function HamburgerMenu(props: {
    data: { [k: string]: string };
}) {
    const classes = useStyles();
    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });

    const toggleDrawer = (anchor: Anchor, open: boolean) => (
        event: React.KeyboardEvent | React.MouseEvent,
    ) => {
        if (
            event.type === 'keydown' &&
            ((event as React.KeyboardEvent).key === 'Tab' ||
                (event as React.KeyboardEvent).key === 'Shift')
        ) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };

    const list = (anchor: Anchor) => (
        <div
            className={clsx(classes.list, {
                [classes.fullList]: anchor === 'top' || anchor === 'bottom',
            })}
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
        >
            <List>
                {Object.entries(props.data).map(([ref, text]) => (
                    <Link
                        style={{ textDecoration: 'none' }}
                        to={`?hideLanguageModal=true#${ref}`}
                        key={`link-${ref}`}
                    >
                        <ListItem button key={text}>
                            <ListItemText
                                primary={text}
                                style={{ textAlign: 'center' }}
                            />
                        </ListItem>
                    </Link>
                ))}
            </List>
        </div>
    );

    return (
        <>
            <LargeMenuContainer
                style={{
                    position: 'relative',
                    float: 'left',
                }}
            >
                {Object.entries(props.data).map(([ref, text]) => (
                    <Link
                        style={{
                            textDecoration: 'none',
                            color: 'white',
                            padding: '0.6em',
                        }}
                        to={`?hideLanguageModal=true#${ref}`}
                        key={`link-${ref}`}
                    >
                        {text}
                    </Link>
                ))}
            </LargeMenuContainer>
            <MobileMenuContainer
                style={{
                    position: 'absolute',
                    zIndex: 1,
                    top: 0,
                    left: '50%',
                    transform: 'translateX(-50%)',
                }}
            >
                <React.Fragment key={'top'}>
                    <Button
                        style={{ color: 'white' }}
                        onClick={toggleDrawer('top', true)}
                    >
                        <MenuIcon />
                    </Button>
                    <Drawer
                        anchor={'top'}
                        open={state['top']}
                        onClose={toggleDrawer('top', false)}
                    >
                        {list('top')}
                    </Drawer>
                </React.Fragment>
            </MobileMenuContainer>
        </>
    );
}
