import React, { FunctionComponent } from 'react';
import { GlobalStyle, theme, ThemeContext } from '../../style';
import { Inner } from './style';
import { Props } from './type';
import { Footer } from '../Footer';
import { Header } from '../Header';

const Layout: FunctionComponent<Props> = ({ children }) => {
    return (
        <ThemeContext.Provider value={theme}>
            <GlobalStyle />
            <Header />
            <Inner>{children}</Inner>
            <Footer />
        </ThemeContext.Provider>
    );
};

export default Layout;
